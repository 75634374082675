exports.components = {
  "component---src-components-aktualita-page-tsx": () => import("./../../../src/components/aktualitaPage.tsx" /* webpackChunkName: "component---src-components-aktualita-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aktuality-tsx": () => import("./../../../src/pages/aktuality.tsx" /* webpackChunkName: "component---src-pages-aktuality-tsx" */),
  "component---src-pages-areal-tsx": () => import("./../../../src/pages/areal.tsx" /* webpackChunkName: "component---src-pages-areal-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kamery-tsx": () => import("./../../../src/pages/kamery.tsx" /* webpackChunkName: "component---src-pages-kamery-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-obcerstveni-tsx": () => import("./../../../src/pages/obcerstveni.tsx" /* webpackChunkName: "component---src-pages-obcerstveni-tsx" */),
  "component---src-pages-pocasi-tsx": () => import("./../../../src/pages/pocasi.tsx" /* webpackChunkName: "component---src-pages-pocasi-tsx" */),
  "component---src-pages-servis-tsx": () => import("./../../../src/pages/servis.tsx" /* webpackChunkName: "component---src-pages-servis-tsx" */),
  "component---src-pages-skola-tsx": () => import("./../../../src/pages/skola.tsx" /* webpackChunkName: "component---src-pages-skola-tsx" */)
}

